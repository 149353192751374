<template>
    <div class="content" style="background: #f1f1f1;" :class="{'hui': resData.ishui == 'Y'}">
       <Header></Header>
       <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1200 list-div">
           <div class="list-div-left">
                <img src="static/jr/listnan.png" alt="" style="width: 100%;">
                <ul class="tabs" v-for="(list,i) in columnList" :key="i">
                    <li class="li-tab" v-for="(item,index) in list.stypes" :key="index"
                    @click="toggleTabs(item.id)"
                    :class="{active:item.id==id}"><p>{{item.title}}</p>
                    </li>
                </ul>
            </div>
            <div class="list-div-right">
                 <!-- v-show="nowIndex===0" -->
                <div class="divTab">
                    <!-- <div class="divTab-top">
                        <p style="display:flex;">
                            <span class="red"></span>
                            <span style="color: #ba291c;display: inline-block;margin-top: 8px;padding-left: 10px;">学校概况</span>
                        </p>
                    </div> -->
                    <ul class="divTab-ul">
                        <li v-for="(item,i) in list" :key="i"  @click="cookie(item)">
                            <p class="ell">{{item.title}}</p>
                            <p class="time">{{item.ctime | timeLenght }}</p>
                        </li>
                    </ul>
                    <div class="pagination">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            background
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageNum"
                            layout="total, prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="350px">
            <p>您没有权限，请先登录！
                <el-button type="primary" style="margin-left:10px;" @click="login">登录</el-button>
            </p>
        </el-dialog>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './components/footer'
import Header from './components/subpageHeader'
import JrApi from '../../lvya-common/src/api/eos/jr'
import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
export default {
    components: {
        Footer,
        Header
    },
    data() {
        return {
            tabsParam: ['学校简介', '治理结构'],
            nowIndex: 0,
            banner: '',
            columnList: [],
            list: [],
            id: 0,
            id2: '',
            currentPage: 1, // 默认在第一页
            pageNum: 10, // 默认每页显示1条数据
            total: 0, // 默认总条数为一条
            resData: '',
            dialogVisible: false,
        }
    },
    created() {
        this.getId()
        this.ColumnList()
        this.getPaging()
        this.HF()
        // this.List()
    },
    // 监听路由参数
    watch: {
        '$route'(to, from) {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            // this.List()
            this.ColumnList()
            this.getPaging()
        //    console.log(this.$route.query.pid, this.$route.query.id)
        }
    },
    methods: {
        cookie(item) {
            if (item.isrole == 'Y') {
                let EOSToken = Cookies.get('EOSToken')
                if (EOSToken) {
                    this.$router.push({ path: '/details', query: { id: item.id, pid: this.pid, id2: this.id }})
                } else {
                    this.id = item.id
                    this.dialogVisible = true
                }
            } else {
                this.$router.push({ path: '/details', query: { id: item.id, pid: this.pid, id2: this.id }})
            }
        },
        login() {
            window.location.href = `http://jrzz.lvya.org/eos-web/#/login?url=http://www.jsjrzz.net/#/details?id=${this.id}&pid=${this.pid}`
        },
        toggleTabs: function(id) {
            this.id = id
            // this.List()
            this.getPaging()
        },
        // 跳转二级页面
        // hrefUrl(item) {
        //     this.$router.push({ path: '/details', query: { id: item.id, pid: this.pid, id2: this.id }})
        // },
        getId() {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            // console.log(this.pid, this.id)
        },
         // banner图
        async Banner() {
            const resData = await JrApi.Banner({ typeid: this.pid })
            this.banner = resData
            // console.log(this.banner)
        },
        // 左侧栏目导航
        async ColumnList() {
            const resData = await JrApi.Yycenter({ pid: this.pid })
            this.columnList = resData
            this.id2 = resData[0].stypes[0].id
            // console.log(this.id2)
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
        },
        // 右侧列表
        // async List() {
        //     const resData = await JrApi.Xxxw({ pid: this.pid, typeid: this.id })
        //     this.list = resData
        //     // console.log(this.list)
        // },
        getPaging(pid, typeid) {
            axios.post(window.GateWayServer + '/schoolsite/api/38/getArts', qs.stringify({
                pid: this.pid,
                typeid: this.id,
                pageNo: this.currentPage,
                pageSize: this.pageNum
            }))
            .then(res => {
                // console.log(res.data.count)
                // console.log('444')
                this.list = res.data.data
                this.total = Number(res.data.count)
            })
        },
        handleCurrentChange: function(currentPage) {
            this.currentPage = currentPage
            this.getPaging()
        },
        handleSizeChange(val) {
            this.pageNum = val
            this.getPaging()
        },
    },
    mounted() {
        this.Banner()
    }
}
</script>

<style scope>
@font-face {
 font-family: 'qigong';
 src: url('../../font/qigong.TTF');
}
p{
    margin-block-start: 0em;
    margin-block-end: 0em;
}
ul{
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
}
.content {
  width: 100%;
}
.hui{
  -webkit-filter:grayscale(100%)
}
.content-1200{
    width: 1200px;
    min-height: 300px;
    margin: 20px auto;
}
.list-div{
    min-height: 300px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
}
.list-div-left{
    width: 230px;
}
.list-div-right{
    width: 80%;
    flex: 1;
    padding: 0 15px;
    box-sizing: border-box;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url('../../../public/static/jr/banner2.png') center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.active{
    background: #9d2228;
    color: #fff;
}
.tabs{
    width: 100%;
    font:0;
    padding:0;
    background: #eee;
    margin-top: -4px;
}
.li-tab{
    width: 100%;
    line-height: 50px;
    /* height: 100%; */
    display:inline-block;
    text-align: center;
    cursor: pointer;
}
.divTab{
    width: 100%;
    min-height: 300px;
}
.divTab-top{
    border-bottom: 1px solid #eee;
}
.red {
    width: 4px;
    height: 35px;
    background: #ba291c;
    display: inline-block;
}
.divTab-ul{
    width: 100%;
    margin-top: 10px;
}
.divTab-ul li{
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #555;
}
.divTab-ul li:hover{
    color: #9d2228;
    cursor: pointer;
}
.divTab-ul li .time{
    color: #777;
}
.divTab-ul li .ell{
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #bd2b17;
}
.el-pagination{
    margin-top: 10px;
    padding-left: 0px;
}
</style>
